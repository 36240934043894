<template>
  <div id="app">
    <h1>聊天机器人 - 小安</h1>
    <div>
      <input v-model="question" placeholder="请输入问题" />
      <button @click="sendQuestion">发送</button>
      <button @click="sendQuestion2">消息</button>
    </div>
    <div>
      <h2>回答：</h2>
      <p>{{ answer }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      question: "", // 用户输入的问题
      answer: "", // 从后端接收的回答
      talkid:"",
    };
  },
  mounted() {
    this.SSEinite();




  },
  methods: {
    // sendQuestion() {
    //   // 清空之前的回答
    //   this.answer = "正在加载回答...";
    //
    //   // 使用 fetch 发送 POST 请求
    //   fetch('/py/get_answer2', {
    //     method: 'POST',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({ question: this.question })
    //   })
    //       .then(response => {
    //         // 处理流式响应
    //         const reader = response.body.getReader();
    //         const stream = new ReadableStream({
    //           start(controller) {
    //             function push() {
    //               reader.read().then(({ done, value }) => {
    //                 if (done) {
    //                   controller.close();
    //                   return;
    //                 }
    //                 controller.enqueue(value);
    //                 push();
    //               }).catch(err => {
    //                 console.error('Stream error:', err);
    //                 controller.error(err);
    //               });
    //             }
    //             push();
    //           }
    //         });
    //
    //         return new Response(stream, { headers: { 'Content-Type': 'text/plain' } }).text();
    //       })
    //       .then(data => {
    //         // 假设返回的数据是 JSON 格式的字符串
    //         const jsonData = JSON.parse(data);
    //         this.answer = jsonData.choices[0].message.content;
    //       })
    //       .catch(error => {
    //         console.error("请求出错", error);
    //         this.answer = "出错了，请稍后再试";
    //       });
    // }
    SSEinite() {
      const self = this
      this.$axios.get("http://localhost:25145/").then((event)=>{
        this.talkid=event.data["id"]
        alert(this.talkid)

        this.eventSource = new EventSource('http://localhost:25145/stream?channel='+event.data["id"]);
        this.eventSource.addEventListener('message_event', function(event) {
          var data = JSON.parse(event.data);
          console.log("服务器说: " + data.message);
          self.answer=data.message
        }, false);
      })


/*      this.eventSource.onmessage = (event) => {
        print("123123213123123")
        const data = JSON.parse(event.data)//将字符串转换成json格式
        if (data.number !== 'done') {
          this.answer.push(data.number);
        } else {
          this.eventSource.close();
        }
      };

      this.eventSource.onerror = (error) => {
        if (this.eventSource.readyState === EventSource.CLOSED) {
          console.log('Connection to server closed');
        } else {
          console.error('Error occurred:', error);
        }
      };*/
    },
    sendQuestion() {

      this.$axios.post("http://localhost:25145/get_answer2",{
        question:"你好",
        client_id:this.talkid
      }).then()
      // this.eventSource = new EventSource('http://localhost:9000/123');
      // this.eventSource.addEventListener('message_event', function(event) {
      //   var data = JSON.parse(event.data);
      //   alert("服务器说: " + data.message);
      // }, false);

/*      this.eventSource.onmessage = (event) => {
        print("123123213123123")
        const data = JSON.parse(event.data)//将字符串转换成json格式
        if (data.number !== 'done') {
          this.answer.push(data.number);
        } else {
          this.eventSource.close();
        }
      };

      this.eventSource.onerror = (error) => {
        if (this.eventSource.readyState === EventSource.CLOSED) {
          console.log('Connection to server closed');
        } else {
          console.error('Error occurred:', error);
        }
      };*/
    }
  }
};
</script>

<style>
#app {
  text-align: center;
  margin-top: 50px;
}
</style>
